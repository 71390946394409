// Styling for the portfolio grid section
.portfolio {
  margin-bottom: -30px;
  .portfolio-item {
    cursor: pointer;
    position: relative;
    display: block;
    max-width: 25rem;
    margin-bottom: 30px;
    border-radius: $border-radius;
    overflow: hidden;
    .portfolio-item-caption {
      position: absolute;
      top: 0;
      left: 0;
      transition: $transition-base;
      opacity: 0;
      background-color: fade-out($primary, .1);
      &:hover {
        opacity: 1;
      }
      .portfolio-item-caption-content {
        font-size: 1.5rem;
      }
    }
  }
}

// Styling for the portfolio modals
.portfolio-modal {
  .portfolio-modal-title {
    font-size: 2.25rem;
    line-height: 2rem;
    @include media-breakpoint-up(lg) {
      font-size: 3rem;
      line-height: 2.5rem;
    }
  }
  .close {
    position: absolute;
    z-index: 1;
    right: 1.5rem;
    top: 1rem;
    font-size: 3rem;
    line-height: 3rem;
    color: $primary;
    opacity: 1;
  }
}
