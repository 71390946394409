// Styling for page sections
.page-section {
  padding: 6rem 0;
  .page-section-heading {
    font-size: 2.25rem;
    line-height: 2rem;
  }
  @include media-breakpoint-up(lg) {
    .page-section-heading {
      font-size: 3rem;
      line-height: 2.5rem;
    }
  }
}
