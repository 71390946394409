// Styling for the navbar
#mainNav {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  .navbar-brand {
    color: $white;
  }
  .navbar-nav {
    margin-top: 1rem;
    li.nav-item {
      a.nav-link {
        color: $white;
        &:hover {
          color: $primary;
        }
        &:active,
        &:focus {
          color: $white;
        }
        &.active {
          color: $primary;
        }
      }
    }
  }
  .navbar-toggler {
    font-size: 80%;
    padding: 0.8rem;
  }
}
@include media-breakpoint-up(lg) {
  #mainNav {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    .navbar-brand {
      font-size: 1.75em;
      transition: font-size 0.3s;
    }
    .navbar-nav {
      margin-top: 0;
    }
    .navbar-nav > li.nav-item > a.nav-link.active {
      color: $white;
      background: $primary;
      &:active,
      &:focus,
      &:hover {
        color: $white;
        background: $primary;
      }
    }
  }
  #mainNav.navbar-shrink {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    .navbar-brand {
      font-size: 1.5em;
    }
  }
}
