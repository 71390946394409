// Custom divider with icon
.divider-custom {
  margin: 1.25rem 0 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .divider-custom-line {
    width: 100%;
    max-width: 15rem;
    height: 0.25rem;
    background-color: $secondary;
    border-radius: 1rem;
    border-color: $secondary !important;
    &:first-child {
      margin-right: 1rem;
    }
    &:last-child {
      margin-left: 1rem;
    }
  }
  .divider-custom-icon {
    color: $secondary !important;
    font-size: 2rem;
  }
  &.divider-light {
    .divider-custom-line {
      background-color: $white;
    }
    .divider-custom-icon {
      color: $white !important;
    }
  }
}
